















import { inPlugin } from "@/utils/base"
import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "Home"
})
export default class Home extends Vue {
  titleIcon: string = require("@/assets/images/文字logo.png")
  phoneIcon: string = require("@/assets/images/icon_phone2.png")
  searchIcon: string = require("@/assets/images/icon_search.png")

  handleSearch() {
    if (this.$route.name === "toolkit") {
      this.$router.push("/toolkit/search")
      return
    }
    this.$router.push("/search")
  }

  handleLogo() {
    this.$router.push("/")
  }

  handleDownload() {
    if (inPlugin()) {
      if (window.Java && window.Java.openInExternalApp) {
        window.Java.openInExternalApp(
          "https://download.ghzs.com/ghzs?channel=GH_112"
        )
      }
      return
    }
    window.open("https://download.ghzs.com/ghzs?channel=GH_112", "_blank")
  }
}
