






































































import { NVideoComment } from "@/api/modules/video"
import {
  getUserToken,
  inPlugin,
  checkPluginLogin,
  timeago,
  formatURLInHTML
} from "@/utils/base"
import { Component, Vue, Prop } from "vue-property-decorator"
import { ImagePreview } from "vant"
import downloadDialog from "@/components/downloadDialog"
import { useContentApi } from "@/api/useContentApi"
import commentDialog from "@/components/commentDialog"

@Component({
  name: "CommentItem"
})
export default class CommentItem extends Vue {
  @Prop({ type: Object, default: () => {} }) data: NVideoComment.IItem

  timeago = timeago
  formatURLInHTML = formatURLInHTML

  handleItem(images, idx) {
    ImagePreview({
      images,
      startPosition: idx
    })
  }

  handleDownload() {
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  isInPlugin = inPlugin()
  isPluginLogin = checkPluginLogin()
  async handleVote(item) {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      const isVote = item?.me?.is_video_comment_voted || false
      const api = useContentApi(getUserToken())
      if (isVote) {
        try {
          await api.unvote_comment.video(item._id)
          item.me = {
            ...item.me,
            is_video_comment_voted: false
          }
          if (item.vote) {
            item.vote--
          } else {
            item.vote = 0
          }
        } catch (error) {
          console.error(error)
          this.$toast("取消点赞失败" + error?.response?.data?.code)
        }
      } else {
        const api = useContentApi(getUserToken())
        try {
          await api.vote_comment.video(item._id)
          item.me = {
            ...item.me,
            is_video_comment_voted: true
          }
          if (item.vote) {
            item.vote++
          } else {
            item.vote = 1
          }
        } catch (error) {
          console.error(error)
          this.$toast("点赞失败" + error?.response?.data?.code)
        }
      }

      return
    }

    this.$downloadDialog()
  }

  handleComment(item) {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      const api = useContentApi(getUserToken())
      commentDialog(
        {
          rmAfterDestroy: true,
          presetData: {}
        },
        async ({ content }) => {
          try {
            await api.reply_comment.video(item._id, { content })
            this.$parent["refresh"]()
          } catch (error) {
            console.log(error)
            this.$toast("评论失败" + error?.response?.data?.code)
          }
        }
      )
      return
    }
    this.$downloadDialog()
  }
}
