


















































import { inGhzs, inPlugin } from "@/utils/base"
import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  name: "Home",
  components: {
    Header: () => import("./components/Header.vue"),
    Backtop: () => import("@/components/backtop/backtop.vue"),
    FloatDownload: () => import("@/components/floatDownload/floatDownload.vue"),
    Footer: () => import("@/components/footer/footer.vue")
  }
})
export default class Home extends Vue {
  menu = null
  menus = [
    {
      name: "首页",
      path: "/",
      activeIcon: require("@/assets/images/icon_home1.png"),
      icon: require("@/assets/images/icon_home2.png")
    },
    {
      name: "论坛",
      path: "/bbs",
      activeIcon: require("@/assets/images/icon_forum1.png"),
      icon: require("@/assets/images/icon_forum2.png")
    },
    {
      name: "排行榜",
      path: "/top",
      activeIcon: require("@/assets/images/icon_ranking1.png"),
      icon: require("@/assets/images/icon_ranking2.png")
    },
    {
      name: "新奇",
      path: "/column",
      activeIcon: require("@/assets/images/icon_novel1.png"),
      icon: require("@/assets/images/icon_novel2.png")
    },
    {
      name: "工具",
      path: "/toolkit",
      activeIcon: require("@/assets/images/icon_tool1.png"),
      icon: require("@/assets/images/icon_tool2.png")
    }
  ]
  isInGhzs = inGhzs()
  isInPlugin = inPlugin()

  showFloatDownload: boolean = true // 显示下载提示条
  // 关闭下载提示条
  handleCloseFloatDownLoad() {
    this.showFloatDownload = false
  }

  mounted() {
    ;(this.$refs.homeMain as HTMLElement).style.height =
      window.innerHeight - 102 + "px"

    this.menu = this.$route.path

    this.$nextTick(() => {
      this.showFloatDownload = !(this.isInGhzs || this.isInPlugin)
    })
  }

  @Watch("$route.path")
  onPath() {
    if (this.$route.path.includes("/top")) {
      this.menu = "/top"
    } else {
      this.menu = this.$route.path
    }
  }
}
