





































































































































import { Component, Vue, Watch } from "vue-property-decorator"

import updateTdk, { formatTDK } from "@/utils/tdk"
import { useContentApi } from "@/api/useContentApi"
import downloadDialog from "@/components/downloadDialog"
import shareDialog from "@/components/shareDialog"
import {
  inGhzs,
  timeago,
  urlScheme,
  inPlugin,
  getUserToken,
  checkPluginLogin
} from "@/utils/base"

@Component({
  name: "Question",
  components: {
    Content: () => import("@/views/CommunityArticle/components/content.vue"),
    Comment: () => import("./components/comment.vue"),
    FloatDownload: () => import("@/components/floatDownload/floatDownload.vue"),
    CommentInput: () => import("@/components/CommentInput"),
    OpenAppButton: () => import("@/components/openAppButton/openAppButton.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Question extends Vue {
  isInGhzs = inGhzs()
  isInPlugin = inPlugin()
  isPluginLogin = checkPluginLogin()

  get publishTime() {
    return this.detail.time?.create
      ? timeago(this.detail.time?.create * 1000)
      : ""
  }

  get detail() {
    return this.$root.$tstore.modules.ques.getters.detail
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (!this.detail) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  mounted() {
    if (this.isInPlugin && this.isPluginLogin && getUserToken()) {
      this.getStatus()
    }
    this.$nextTick(() => {
      this.showFloatDownload = !(this.isInGhzs || this.isInPlugin)
    })
  }

  fetchData() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.ques.actions.FETCH_DETAIL({
          ques_id: this.$route.params.ques_id
        })
        this.formatData()

        await this.$store.dispatch("tdk/FETCH_SUB_TDK", {
          type: "bbs",
          _id_or_name: this.$route.params.ques_id,
          sub_type: "question"
        })

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{问题标题}",
              replaceVal: this.detail.title
            },
            {
              replaceKey: "{问题描述}",
              replaceVal: this.detail.content
                .replace(/<\/?.+?\/?>/g, "")
                .replace(/\n/g, "")
                .replace(/&nbsp/g, "")
                .substr(0, 50)
            },
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.detail.bbs?.game?.name || ""
            }
          ])
        )

        updateTdk.call(this)

        resolve("")
      } catch (error) {
        reject(error)
      }
    })
  }

  formatData() {
    this.detail.content = this.detail.content.replace(
      /https:\/\/resource.ghzs.com\/image\/community\/5b694f242924bcf82441b3fc\.png/g,
      "http://static-web.ghzs.com/website-static/images/icon_article.png"
    )
    this.detail.content = this.detail.content.replace(
      /https:\/\/resource.ghzs.com\/image\/community\/575fa87d8ab49e12658b4cds\.png/g,
      "http://static-web.ghzs.com/website-static/images/icon_reply.png"
    )
    this.detail.content = this.detail.content.replace(
      /ghzhushou:\/\/[^("|')]*/g,
      match => {
        return `javascript:void(0); redirect('${match}')`
      }
    )
  }

  destroyed() {
    this.$root.$tstore.modules.ques.mutations.SET_DETAIL({ detail: null })
  }

  /** 顶部 */
  showTitle = false
  onScroll({ scrollTop, isFixed }) {
    this.showTitle = scrollTop > 30
  }

  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  handleDownload() {
    this.$downloadDialog()
  }

  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }

  showFloatDownload = !(this.isInGhzs || this.isInPlugin)
  // 关闭下载提示条
  handleCloseFloatDownLoad() {
    this.showFloatDownload = false
  }

  async getStatus() {
    const api = useContentApi(getUserToken())
    const res = await api.quesDetail(this.$route.params.ques_id)
    this.isStar = res.data?.me?.is_favorite || false
  }

  isStar = false
  async onStar(change) {
    console.log("onStar", change)
    const api = useContentApi(getUserToken())
    if (change) {
      try {
        await api.favorites.question(this.detail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await api.cancel_favorites.question(this.detail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    }
  }

  commentKey = 0
  async onComment(content) {
    const api = useContentApi(getUserToken())
    try {
      await api.comment.question(this.detail._id, { content })
      this.commentKey++
    } catch (error) {
      console.error(error)
    }
  }
}
