import Api from "@/api/api"
import { timeago } from "@/utils/base"

export const article = {
  namespaced: true,
  state: () => ({
    detail: null,
    commentList: null,
    count: null
  }),
  mutations: {
    SET_DETAIL: (state, { detail }) => {
      state.detail = detail
    },
    SET_COMMENT_LIST: (state, { commentList }) => {
      state.commentList = commentList
    },
    SET_COUNT: (state, { count }) => {
      state.count = count
    }
  },
  actions: {
    FETCH_DETAIL: ({ commit }, { article_id }) => {
      return Api.article.detail(article_id).then(res => {
        const detail = res.data
        detail.content = detail.content.replace(
          /https:\/\/resource.ghzs.com\/image\/community\/5b694f242924bcf82441b3fc\.png/g,
          "http://static-web.ghzs.com/website-static/images/icon_article.png"
        )
        detail.content = detail.content.replace(
          /https:\/\/resource.ghzs.com\/image\/community\/575fa87d8ab49e12658b4cds\.png/g,
          "http://static-web.ghzs.com/website-static/images/icon_reply.png"
        )
        detail.content = detail.content.replace(
          /ghzhushou:\/\/[^("|')]*/g,
          match => {
            return `javascript:void(0); redirect('${match}')`
          }
        )
        commit("SET_DETAIL", { detail })
      })
    },
    FETCH_COMMENT_LIST: ({ commit }, { article_id, params }) => {
      let articleId: string = article_id
      if (articleId.endsWith(".html")) {
        articleId = articleId.replace(/\.html$/, "")
      }
      return Api.article.comments(articleId, params).then(res => {
        commit("SET_COMMENT_LIST", {
          commentList: res.data.map(v => {
            if (v.list_reply && v.list_reply.length > 3) {
              v.isExpand = false
            }
            v.timeago = timeago(v.time * 1000)
            return v
          })
        })
      })
    },
    FETCH_COUNT: ({ commit }, { article_id }) => {
      return Api.article
        .count(article_id)
        .then(res => commit("SET_COUNT", { count: res.data[0]?.num || "" }))
    }
  }
}
