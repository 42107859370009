import { render, staticRenderFns } from "./Video.vue?vue&type=template&id=5a2194f4&scoped=true"
import script from "./Video.vue?vue&type=script&lang=ts"
export * from "./Video.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Video.vue?vue&type=style&index=0&id=5a2194f4&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5a2194f4",
  "7b16f32f"
  
)

export default component.exports