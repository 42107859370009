import { render, staticRenderFns } from "./Question.vue?vue&type=template&id=a77cc942&scoped=true"
import script from "./Question.vue?vue&type=script&lang=ts"
export * from "./Question.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./Question.vue?vue&type=style&index=0&id=a77cc942&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a77cc942",
  "0c12e577"
  
)

export default component.exports