










































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator"
import { ImagePreview } from "vant"
import api from "@/api/api"
import {
  getUserToken,
  inPlugin,
  checkPluginLogin,
  timeago,
  formatURLInHTML
} from "@/utils/base"
import { IQuesCommentItem } from "@/api/modules/question"
import { useContentApi } from "@/api/useContentApi"
import commentDialog from "@/components/commentDialog"

interface IListItem extends IQuesCommentItem {
  list_reply: any[]
  isExpand: boolean
  timeago: string
}

@Component({
  name: "Comment",
  components: {
    LoadMore: () => import("@/components/loadMore/loadMore.vue"),
    NoMoreData: () => import("@/components/noMoreData/noMore.vue"),

    LoadMoreBtn: () => import("@/components/LoadMoreBtn/LoadMoreBtn.vue")
  }
})
export default class Comment extends Vue {
  formatURLInHTML = formatURLInHTML

  get detail() {
    return this.$root.$tstore.modules.ques.getters.detail
  }

  get commentList() {
    return this.$root.$tstore.modules.ques.getters.commentList
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  mounted() {
    if (this.isInPlugin && this.isPluginLogin) {
      this.fetchData()
    } else {
      if (this.commentList) {
        this.formatData()
      } else {
        this.fetchData()
      }
    }
  }

  fetchData() {
    return this.$root.$tstore.modules.ques.actions
      .FETCH_COMMENT_LIST({
        ques_id: this.$route.params.ques_id,
        params: {
          view: "bbs_web",
          page: this.page,
          page_size: this.pageSize,
          sort: this.sort
        }
      })
      .then(this.formatData)
      .finally(() => {
        this.loading = false
      })
  }
  formatData() {
    if (this.commentList && this.commentList.length === 0) {
      this.finished = true
    }
    this.list = this.list.concat(
      this.commentList.map(v => {
        if (v.attached && !v.list_reply) {
          v.list_reply = v.attached
        }
        if (v.list_reply && v.list_reply.length > 3) {
          v.isExpand = false
        }
        v.timeago = timeago(v.time * 1000)
        return v
      })
    )

    if (!this.$isServer) {
      this.prefetchNextPage()
    }
  }

  destroyed() {
    this.$root.$tstore.modules.ques.mutations.SET_COMMENT_LIST({
      commentList: null
    })
  }

  page = 1
  pageSize = 20
  loading = false
  finished = false
  list: IListItem[] = []
  nextList: IListItem[] = []
  handleLoadMore() {
    this.page++
    if (this.nextList.length > 0) {
      this.list = this.list.concat(this.nextList)
      this.prefetchNextPage()
      return
    }
    this.fetchComments()
  }

  async fetchComments() {
    const res = await api.question.comments(this.$route.params.ques_id, {
      view: "bbs_web",
      page: this.page,
      page_size: this.pageSize,
      sort: this.sort
    })
    if (res.data.length === 0) {
      this.finished = true
    } else {
      this.list = this.list.concat(
        res.data.map((v: IListItem) => {
          if (v.list_reply && v.list_reply.length > 3) {
            v.isExpand = false
          }

          v.timeago = timeago(v.time * 1000)
          return v
        })
      )
      this.prefetchNextPage()
    }
  }
  async prefetchNextPage() {
    const nextRes = await api.question.comments(this.$route.params.ques_id, {
      view: "bbs_web",
      page: this.page + 1,
      page_size: this.pageSize,
      sort: this.sort
    })
    if (nextRes.data.length === 0) {
      this.finished = true
      this.nextList = []
    } else {
      this.nextList = nextRes.data.map((v: IListItem) => {
        if (v.list_reply && v.list_reply.length > 3) {
          v.isExpand = false
        }

        v.timeago = timeago(v.time * 1000)
        return v
      })
    }
  }

  sortType = 1 // 1 -1
  get sort() {
    return `time.create:${this.sortType}`
  }

  handleDownload() {
    this.$downloadDialog()
  }

  @Watch("sortType")
  onType(c) {
    this.page = 1
    this.list = []
    this.nextList = []
    this.loading = true
    this.finished = false
    this.fetchComments()
  }

  handleMore(item) {
    item.isExpand = true
    this.$forceUpdate()
  }

  preview(images, idx) {
    ImagePreview({
      images,
      startPosition: idx
    })
  }

  isInPlugin = inPlugin()
  isPluginLogin = checkPluginLogin()
  async handleVote(item) {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      const isVote = item?.me?.is_question_comment_voted || false
      const api = useContentApi(getUserToken())
      if (isVote) {
        try {
          await api.unvote_comment.question(item._id)
          // item.me = {
          //   ...item.me,
          //   is_question_comment_voted: false
          // }
          this.$set(item, "me", {
            ...item.me,
            is_question_comment_voted: false
          })
          if (item.vote) {
            item.vote--
          } else {
            item.vote = 0
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        const api = useContentApi(getUserToken())
        try {
          await api.vote_comment.question(item._id)
          // item.me = {
          //   ...item.me,
          //   is_question_comment_voted: true
          // }
          this.$set(item, "me", {
            ...item.me,
            is_question_comment_voted: true
          })
          if (item.vote) {
            item.vote++
          } else {
            item.vote = 1
          }
        } catch (error) {
          console.error(error)
        }
      }
      return
    }

    this.$downloadDialog()
  }

  handleComment(item) {
    if (this.isInPlugin && this.isPluginLogin) {
      if (!getUserToken()) {
        this.$toast("请先登录")
        return
      }
      const api = useContentApi(getUserToken())
      commentDialog(
        {
          rmAfterDestroy: true,
          presetData: {}
        },
        async ({ content }) => {
          try {
            await api.reply_comment.question(item._id, { content })
            this.$toast("评论成功")
            setTimeout(() => {
              this.refresh()
            }, 1000)
          } catch (error) {
            console.log(error)
            this.$toast("评论失败" + error?.response?.data?.code)
          }
        }
      )
      return
    }
    this.$downloadDialog()
  }

  refresh() {
    this.page = 1
    this.list = []
    this.nextList = []
    this.finished = false
    this.fetchComments()
  }
}
