













































































































import { Component, Vue } from "vue-property-decorator"
import downloadDialog from "@/components/downloadDialog"
import shareDialog from "@/components/shareDialog"

import updateTdk, { formatTDK } from "@/utils/tdk"
import {
  inGhzs,
  timeago,
  urlScheme,
  inPlugin,
  getUserToken
} from "@/utils/base"
import { useContentApi } from "@/api/useContentApi"

@Component({
  name: "Article",
  components: {
    Content: () => import("@/views/CommunityArticle/components/content.vue"),
    Comment: () => import("./components/comment.vue"),
    FloatDownload: () => import("@/components/floatDownload/floatDownload.vue"),
    CommentInput: () => import("@/components/CommentInput"),
    OpenAppButton: () => import("@/components/openAppButton/openAppButton.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Article extends Vue {
  isInGhzs = inGhzs()
  isInPlugin = inPlugin()

  publishTime = ""
  get detail() {
    const TIME = this.$store.state.article.detail?.time
    this.publishTime = TIME && timeago(TIME * 1000)
    return this.$store.state.article.detail
  }

  get hideOthers() {
    return this.detail && this.detail.type && this.detail.type === "隐私政策"
  }

  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  async beforeMount() {
    if (!this.$store.state.article.detail) {
      await this.fetchData()
    }

    if (!this.$isServer) {
      window._launchAppUrl = urlScheme.artcle(this.detail._id)
    }
  }

  fetchData() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.$root.$tstore.modules.article.actions.FETCH_DETAIL({
          article_id: this.$route.params.article_id.endsWith(".html")
            ? this.$route.params.article_id.replace(".html", "")
            : this.$route.params.article_id
        })

        if (this.detail.type && this.detail.type === "seo精选内容") {
          await this.$store.dispatch("tdk/FETCH_SUB_TDK", {
            type: "game",
            _id_or_name: this.detail._id,
            sub_type: "seo_article"
          })
        } else {
          await this.$store.dispatch("tdk/FETCH_SUB_TDK", {
            type: "game",
            _id_or_name: this.detail._id,
            sub_type: "article"
          })
        }

        this.$store.commit(
          "tdk/SET_TDK",
          formatTDK(this.$store.state.tdk, [
            {
              replaceKey: "{游戏名称}",
              replaceVal: this.detail.game.name
            },
            {
              replaceKey: "{文章标题}",
              replaceVal: this.detail.title
            },
            {
              replaceKey: "{文章正文内容}",
              replaceVal: this.detail.content
                .replace(/<\/?.+?\/?>/g, "")
                .replace(/\n/g, "")
                .replace(/&nbsp/g, "")
                .substr(0, 50)
            },
            {
              replaceKey: "{文章内容}",
              replaceVal: this.detail.content
                .replace(/<\/?.+?\/?>/g, "")
                .replace(/\n/g, "")
                .replace(/&nbsp/g, "")
                .substr(0, 50)
            },
            {
              replaceKey: "{文章简介}",
              replaceVal: this.detail.excerpt
            },
            {
              replaceKey: "{关键词}",
              replaceVal: this.detail.seo_keyword || ""
            }
          ])
        )
        updateTdk.call(this)

        resolve("")
      } catch (error) {
        reject(error)
        if (!this.$isServer) {
          this.$router.push("/404")
        }
      }
    })
  }
  destroyed() {
    this.$store.commit("article/SET_DETAIL", { detail: null })
  }

  handleBack() {
    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push("/")
    }
  }

  /** 顶部 */
  showTitle = false
  onScroll({ scrollTop, isFixed }) {
    this.showTitle = scrollTop > 30
  }

  handleShare() {
    shareDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  handleDownload() {
    if (this.isInPlugin) {
      return
    }
    downloadDialog(
      {
        rmAfterDestroy: false,
        presetData: {}
      },
      () => {}
    )
  }

  onMounted() {
    this.$nextTick(() => {
      this.showFloatDownload = !(this.isInGhzs || this.isInPlugin)
    })
  }

  showFloatDownload = !(this.isInGhzs || this.isInPlugin)
  // 关闭下载提示条
  handleCloseFloatDownLoad() {
    this.showFloatDownload = false
  }

  isStar = false
  async onStar(change) {
    console.log("onStar", change)
    const api = useContentApi(getUserToken())
    if (change) {
      try {
        await api.favorites.article(this.detail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await api.cancel_favorites.article(this.detail._id)
        this.isStar = change
      } catch (error) {
        console.error(error)
      }
    }
  }

  commentKey = 0
  async onComment(content) {
    const api = useContentApi(getUserToken())
    try {
      await api.comment.article(this.detail._id, { content })
      this.commentKey++
    } catch (error) {
      console.error(error)
    }
  }
}
